import {
  AppointmentResponse,
  AppointmentSchedulingControllerService,
  AvailableSlotResponse,
  AppointmentParticipantResponse,
  AppointmentControllerService
} from "@9amhealth/openapi";
import {
  CalendarDate,
  CalendarDateTime,
  DateValue,
  endOfMonth,
  fromDate,
  getLocalTimeZone,
  startOfMonth,
  toCalendarDate,
  toCalendarDateTime,
  today,
  ZonedDateTime
} from "@internationalized/date";
import { Cubit } from "blac-next";

export type AppointmentSlot = {
  from: ZonedDateTime;
  to: ZonedDateTime;
  componentIdentifier: string;
  participants: Array<AppointmentParticipantResponse>;
};

export type AppointmentSlotGroup = {
  from: ZonedDateTime;
  to: ZonedDateTime;
  slots: AppointmentSlot[];
};

export type SchedulerState = {
  selectedSlot?: AppointmentSlot;
  selectedSlotGroup?: AppointmentSlotGroup;
  selectedDate?: DateValue;
  availableSlotGroups: AppointmentSlotGroup[];
  loading: boolean;
};

export type SchedulerStep = "confirm" | "pick-date" | "pick-slot";

export type SchedulerProps = {
  availableSlots: AppointmentSlot[];
};

export type ScheduleAppointmentTypes = Parameters<
  typeof AppointmentSchedulingControllerService.getAvailableSlots
>[0];

export class SchedulerBloc extends Cubit<SchedulerState, SchedulerProps> {
  availableSlots: AppointmentSlot[] = [];
  daysPerRequest = 5;
  currentAppointmentType?: ScheduleAppointmentTypes;
  rescheduleAppointment?: AppointmentResponse;
  currentMonth = today(getLocalTimeZone()).month;

  constructor() {
    super({
      loading: false,
      availableSlotGroups: []
    });
  }

  get appointmentType(): ScheduleAppointmentTypes | undefined {
    return this.currentAppointmentType ?? this.rescheduleAppointment?.type;
  }

  setProps = (props: SchedulerProps) => {
    this.props = props;
  };

  focusedDate?: DateValue;
  setFocusedDate = (d: DateValue) => {
    this.focusedDate = d;
    this.pickFocusedDate();
  };

  pickFocusedDate = () => {
    if (!this.focusedDate) {
      throw new Error("No date focused");
    }

    this.patch({
      selectedDate: this.focusedDate,
      selectedSlot: undefined,
      selectedSlotGroup: undefined
    });
  };

  clearSelectedDate = () => {
    this.patch({
      selectedDate: undefined,
      selectedSlot: undefined,
      selectedSlotGroup: undefined
    });
  };

  getActiveStep = () => {
    const { selectedDate, selectedSlot } = this.state;

    if (!selectedDate) {
      return "pick-date";
    }

    if (!selectedSlot) {
      return "pick-slot";
    }

    return "confirm";
  };

  getSlotsForDate = (date?: DateValue): AppointmentSlotGroup[] => {
    const selectedDate = date?.toDate(getLocalTimeZone()).toDateString();
    return this.state.availableSlotGroups.filter(
      (s) => selectedDate === s.from.toDate().toDateString()
    );
  };

  getSlotsForSelectedDate = (): AppointmentSlotGroup[] => {
    return this.getSlotsForDate(this.state.selectedDate);
  };

  setSelectedSlot = (slot: AppointmentSlot | undefined) => {
    let { selectedSlotGroup } = this.state;
    // if we have a rescheduleId, and are removing the slot, we should also remove the selectedSlotGroup
    if (slot === undefined && this.rescheduleAppointment) {
      selectedSlotGroup = undefined;
    }
    this.patch({ selectedSlot: slot, selectedSlotGroup });
  };

  setSelectedSlotGroup = (slotGroup: AppointmentSlotGroup | undefined) => {
    let selectedSlot = undefined;
    const { slots = [] } = slotGroup ?? {};

    // if we have a rescheduleId, we immediately set the selectedSlot to the first slot in the slotGroup, if there is only one
    if (slotGroup && this.rescheduleAppointment && slots.length > 0) {
      selectedSlot = slots[0];
    }

    this.patch({ selectedSlotGroup: slotGroup, selectedSlot });
  };

  bookSelectedSlot = async (options: {
    rescheduleAppointment?: AppointmentResponse;
  }): Promise<AppointmentResponse> => {
    if (!this.currentAppointmentType) {
      throw new Error("No appointment type provided");
    }
    if (!this.state.selectedSlot) {
      throw new Error("No selected slot provided");
    }

    const { rescheduleAppointment } = options;
    const isReschedule = rescheduleAppointment !== undefined;

    const start = this.state.selectedSlot.from.toDate().toISOString();
    const end = this.state.selectedSlot.to.toDate().toISOString();
    const participantUserIds = this.state.selectedSlot.participants.map(
      (p) => p.userId
    );

    if (isReschedule) {
      const updatedAppointment =
        await AppointmentControllerService.updateMemberAppointment(
          rescheduleAppointment.id,
          {
            status: rescheduleAppointment.status,
            start,
            end,
            participantUserIds
          }
        );

      return updatedAppointment.data;
    } else {
      const newAppointment =
        await AppointmentSchedulingControllerService.scheduleAppointment(
          this.currentAppointmentType,
          {
            componentIdentifier: this.state.selectedSlot.componentIdentifier,
            start,
            end,
            participantUserIds
          }
        );

      return newAppointment.data;
    }
  };

  clearSelectedSlot = () => {
    this.patch({
      selectedSlot: undefined
    });
  };

  initDatePickerView = async (options: {
    type: ScheduleAppointmentTypes;
    rescheduleAppointment?: AppointmentResponse;
    participantUserIds?: string[];
  }): Promise<void> => {
    if (this.state.loading) return;
    this.currentAppointmentType = options.type;
    this.rescheduleAppointment = options.rescheduleAppointment;
    await this.loadAppointmentSlotsForCurrentMonth();
  };

  addTimeRangeDays = (start: CalendarDate): CalendarDateTime => {
    const end = start.add({ days: this.daysPerRequest });
    let output = toCalendarDateTime(end);

    const eoMonth = endOfMonth(start);
    const maxEnd = end.compare(eoMonth) > 0 ? eoMonth : end;

    if (output.compare(maxEnd) > 0) {
      output = toCalendarDateTime(maxEnd);
    }
    output = output.set({ hour: 23, minute: 59, second: 59 });
    return output;
  };

  loadAppointmentSlotsForTimeRange = async (options: {
    type: ScheduleAppointmentTypes;
    start: CalendarDate;
    end: CalendarDate;
  }): Promise<void> => {
    const { type, start, end } = options;
    try {
      const startDate = toCalendarDateTime(start).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
      const endDate = toCalendarDateTime(end).set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0
      });

      const availableSlots =
        await AppointmentSchedulingControllerService.getAvailableSlots(
          type,
          startDate.toDate(getLocalTimeZone()).toISOString(),
          endDate.toDate(getLocalTimeZone()).toISOString(),
          this.rescheduleAppointment
            ? this.rescheduleAppointment.participants.map((p) => p.userId)
            : undefined
        );

      const slots = this.parseSlotsFromApiResponse(availableSlots.data);

      const mergedSlots = this.mergeSlots(slots);

      this.patch({
        availableSlotGroups: mergedSlots
      });
    } catch (e) {
      console.error(e);
    }
  };

  mergeSlots = (slots: AppointmentSlotGroup[]): AppointmentSlotGroup[] => {
    const mergedSlots: AppointmentSlotGroup[] = [
      ...this.state.availableSlotGroups,
      ...slots
    ];
    const slotMap = new Map<string, AppointmentSlotGroup>();

    mergedSlots.forEach((slot) => {
      const key = `${slot.from.toString()}-${slot.to.toString()}`;
      if (!slotMap.has(key)) {
        slotMap.set(key, slot);
      }
    });

    const uniqueSlots = [...slotMap.values()];

    return uniqueSlots;
  };

  minTimeRangeDays = 2;
  loadCompleteForMonths = new Set<number>();
  loadAppointmentSlotsForCurrentMonth = async (): Promise<void> => {
    this.clearSelectedDate();
    if (this.loadCompleteForMonths.has(this.currentMonth)) {
      return;
    }
    this.patch({
      loading: true
    });
    const requestListForMonth: Parameters<
      typeof this.loadAppointmentSlotsForTimeRange
    >[0][] = [];

    const { currentMonth } = this;
    const dateNow = today(getLocalTimeZone());

    if (!this.currentAppointmentType) {
      throw new Error("No appointment type provided");
    }

    let start =
      currentMonth === dateNow.month
        ? dateNow
        : startOfMonth(dateNow).set({ month: currentMonth, day: 1 });

    let ended = false;
    let limit = 10;
    while (!ended && limit > 0) {
      limit--;
      let end = toCalendarDate(start).add({ days: this.daysPerRequest });

      if (end.month !== currentMonth) {
        end = endOfMonth(start);
        ended = true;
      }

      const timeDiffDays = end.compare(start);
      if (timeDiffDays < this.minTimeRangeDays) {
        end = end.add({ days: this.minTimeRangeDays - timeDiffDays });
      }

      if (end.compare(start) <= 0) {
        break;
      }

      requestListForMonth.push({
        start,
        end,
        type: this.currentAppointmentType
      });
      start = end;
    }

    await Promise.all(
      requestListForMonth.map((request) =>
        this.loadAppointmentSlotsForTimeRange(request)
      )
    );

    this.patch({
      loading: false
    });
    this.loadCompleteForMonths.add(currentMonth);
  };

  debouncedHandleDateFocusChanged: NodeJS.Timeout | number = 0;
  handleDateFocusChanged = (focusedDate: DateValue) => {
    clearTimeout(this.debouncedHandleDateFocusChanged);
    this.debouncedHandleDateFocusChanged = setTimeout(() => {
      const newFocusedMonth = focusedDate.month;
      if (this.currentMonth !== newFocusedMonth) {
        this.currentMonth = newFocusedMonth;
        void this.loadAppointmentSlotsForCurrentMonth();
      }
    }, 300);
  };

  datesAreEqual = (date1: DateValue, date2: DateValue): boolean => {
    const diff = date1.compare(date2);
    return diff === 0;
  };

  parseSlotsFromApiResponse = (
    slots: Array<AvailableSlotResponse>
  ): AppointmentSlotGroup[] => {
    const participantFilter = this.rescheduleAppointment
      ? this.rescheduleAppointment.participants.map((p) => p.userId)
      : undefined;

    const validSlots = participantFilter
      ? slots.filter((slot) => {
          const participants = slot.participants.map((p) => p.userId);
          return participants.every((p) => participantFilter.includes(p));
        })
      : slots;

    const availableSlots = validSlots.map((slot) => {
      const from = fromDate(new Date(slot.start), getLocalTimeZone());
      const to = fromDate(new Date(slot.end), getLocalTimeZone());

      return {
        from,
        to,
        participants: slot.participants,
        componentIdentifier: slot.componentIdentifier
      } satisfies AppointmentSlot;
    });
    this.availableSlots = availableSlots;

    let groupedSlots: AppointmentSlotGroup[] = [];

    // group the slots by the start and end date
    availableSlots.forEach((slot) => {
      const { from } = slot;
      const { to } = slot;
      const slots = groupedSlots.find(
        (s) => this.datesAreEqual(s.from, from) && this.datesAreEqual(s.to, to)
      );
      if (slots) {
        slots.slots.push(slot);
      } else {
        groupedSlots.push({
          from,
          to,
          slots: [slot]
        });
      }
    });

    groupedSlots = groupedSlots.map((sg) => {
      // sort the slots by the first participant's name
      const sortedSlots = sg.slots.sort((a, b) => {
        const aName = a.participants[0].displayName?.toLowerCase() ?? "";
        const bName = b.participants[0].displayName?.toLowerCase() ?? "";
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      });
      return {
        ...sg,
        slots: sortedSlots
      }
    })

    return groupedSlots;
  };
}
