import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import CalendarIconSmall from "../../../icons/IconCalendarSmall";
import IconChevronRightGray from "../../../icons/IconChevronRightGray";
import TimeIconSmall from "../../../icons/IconTimeSmall";
import {
  CalendarDate,
  createCalendar,
  DateValue,
  endOfMonth,
  fromDate,
  getLocalTimeZone,
  getWeeksInMonth,
  now,
  startOfMonth,
  toCalendarDate
} from "@internationalized/date";
import {
  CalendarCellHeader,
  CalendarContainer,
  CalendarTable,
  CalendarTitle,
  Cell,
  Header,
  StyledButton
} from "atom/scheduler/schedulerComponents";
import clsx from "clsx";
import React, {
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef
} from "react";
import {
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
  useDatePicker,
  useLocale
} from "react-aria";
import { CalendarProps } from "react-aria-components";
import {
  CalendarState,
  useCalendarState,
  useDatePickerState
} from "react-stately";
import Button from "atom/button/Button";
import {
  AppointmentSlot,
  AppointmentSlotGroup,
  ScheduleAppointmentTypes,
  SchedulerBloc
} from "./SchedulerBloc";
import { Button as AriaButton } from "react-aria-components";
import {
  AppointmentResponse,
  AppointmentParticipantResponse
} from "@9amhealth/openapi";
import { translate } from "lib/blocs/TranslateionBloc";
import compareTime from "src/lib/compareTime";

const dateToString = (date: DateValue) => {
  return date.toString().split("T")[0];
};

function Calendar(
  props: {
    availableDays: Set<string>;
  } & CalendarProps<DateValue>
) {
  const { locale } = useLocale();
  const minValue = startOfMonth(now(getLocalTimeZone()));
  const maxValue = endOfMonth(minValue.add({ months: 6 }));
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
    isDateUnavailable: (d) => {
      return !props.availableDays.has(dateToString(d));
    },
    minValue,
    maxValue
  });

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar({ ...props }, state);

  return (
    <CalendarContainer {...calendarProps} className="calendar">
      <Header>
        <StyledButton {...prevButtonProps} data-dir="prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="#212121"
              fillRule="evenodd"
              stroke="#212121"
              strokeLinejoin="round"
              strokeOpacity=".6"
              d="M13.6 12.7c.4-.4.4-1 0-1.4L9 6.7a.5.5 0 1 1 .7-.7l5.3 5.3c.4.4.4 1 0 1.4L9.7 18a.5.5 0 0 1-.7-.7l4.6-4.6Z"
              clipRule="evenodd"
            />
          </svg>
        </StyledButton>
        <CalendarTitle>{title}</CalendarTitle>
        <StyledButton {...nextButtonProps}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="#212121"
              fillRule="evenodd"
              stroke="#212121"
              strokeLinejoin="round"
              strokeOpacity=".6"
              d="M13.6 12.7c.4-.4.4-1 0-1.4L9 6.7a.5.5 0 1 1 .7-.7l5.3 5.3c.4.4.4 1 0 1.4L9.7 18a.5.5 0 0 1-.7-.7l4.6-4.6Z"
              clipRule="evenodd"
            />
          </svg>
        </StyledButton>
      </Header>
      <CalendarGrid state={state} />
    </CalendarContainer>
  );
}

function CalendarGrid({ state, ...props }: { state: CalendarState }) {
  const { locale } = useLocale();
  const { gridProps, weekDays } = useCalendarGrid(
    { ...props, weekdayStyle: "short" },
    state
  );

  // Get the number of weeks in the month, so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);
  return (
    <CalendarTable {...gridProps}>
      {weekDays.map((day, index) => (
        <CalendarCellHeader key={index}>
          {day.substring(0, 2)}
        </CalendarCellHeader>
      ))}
      {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
        <React.Fragment key={weekIndex}>
          {state
            .getDatesInWeek(weekIndex)
            .map((date, i) =>
              date ? (
                <CalendarCell key={i} state={state} date={date} />
              ) : (
                <div key={i} />
              )
            )}
        </React.Fragment>
      ))}
    </CalendarTable>
  );
}

function CalendarCell({
  state,
  date
}: {
  state: CalendarState;
  date: CalendarDate;
}) {
  const ref = React.useRef(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    formattedDate
  } = useCalendarCell({ date }, state, ref);

  return (
    <div {...cellProps}>
      <Cell
        {...buttonProps}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={clsx({
          selected: isSelected,
          hidden: isDisabled,
          unavailable: isUnavailable,
          available: !isUnavailable
        })}
      >
        {formattedDate}
      </Cell>
    </div>
  );
}

const PickSlotGrid = styled.div`
  gap: 0.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 90px), 1fr));
  @media (min-width: 280px) {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    grid-template-columns: repeat(3, 1fr);
  }
`;

const SlotButtonWrap = styled.div`
  button {
    --btn-padding-left: 0.2rem !important;
    --btn-padding-right: 0.2rem !important;
    outline: none;
  }

  button[data-theme="transparent"] {
    --inner-background: var(--Secondary-Dark-Cream, #f2efe7);
    @media (pointer: fine) {
      &:hover {
        --inner-background: var(--Greys-White, #fff);
      }
    }
  }
`;

const ProviderButtonWrap = styled.div`
  position: relative;

  button {
    background: transparent;
    display: block;
    width: 100%;
    text-align: left;
    padding: 1rem 0.5rem;
    user-select: none;

    @media (pointer: fine) {
      &:hover {
        background: #0001;
        border-radius: 0.5rem;
      }
    }
  }

  .provider-item {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .avatar {
    width: 44px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--color-cream-darker);
    display: flex;
    justify-content: center;
    align-items: center;
    .avatar-border {
      background: var(--Greys-White, #fff);
    }
  }

  .details {
    display: flex;
    flex-direction: column;
  }

  h5 {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
    margin: 0;
  }

  p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    margin: 0;
  }

  svg {
    margin-left: auto;
  }

  &:after {
    content: "";
    height: 1px;
    left: 0.5rem;
    right: 0.5rem;
    background: #0002;
    position: absolute;
    bottom: 0;
  }

  &:last-of-type:after {
    content: none;
  }

  & button[data-disabled="true"] {
    svg {
      opacity: 0;
    }
  }
`;

const SlotPreview: FC<{ slot: AppointmentSlotGroup }> = ({ slot }) => {
  const [{ selectedSlotGroup }, { setSelectedSlotGroup }] =
    useBloc(SchedulerBloc);
  const from = slot.from
    .toDate()
    .toLocaleString("en-US", { hour: "numeric", minute: "numeric" });

  const isSelected =
    selectedSlotGroup &&
    selectedSlotGroup.from.compare(slot.from) === 0 &&
    selectedSlotGroup.to.compare(slot.to) === 0;

  return (
    <SlotButtonWrap className="slot-button-wrap">
      <Button
        onPress={() => setSelectedSlotGroup(slot)}
        outline
        fullWidth
        theme={isSelected ? "sunrise" : "transparent"}
        hideArrow
        active={isSelected}
      >
        {from}
      </Button>
    </SlotButtonWrap>
  );
};

const ProviderPreview: FC<{
  slot: AppointmentSlot;
  avatar?: (id: string) => ReactElement;
  disabled?: boolean;
  appointmentType?: ScheduleAppointmentTypes;
}> = ({ slot, avatar, disabled, appointmentType }) => {
  const [, { setSelectedSlot }] = useBloc(SchedulerBloc);

  const title = (participant: AppointmentParticipantResponse) =>
    appointmentType
      ? translate("appointment.name", {
          context: appointmentType.toLowerCase().replace("_", "-")
        })
      : participant.displayName;

  const description = (participant: AppointmentParticipantResponse) =>
    appointmentType
      ? translate("appointment.with", {
          name: participant.displayName
        })
      : participant.displayRole;

  return (
    <ProviderButtonWrap
      className="slot-button-wrap"
      style={{ pointerEvents: disabled ? "none" : "auto" }}
    >
      <AriaButton onPress={() => setSelectedSlot(slot)} isDisabled={disabled}>
        {slot.participants.map((participant) => (
          <div className="provider-item" key={participant.userId}>
            <div className="avatar">
              {avatar ? avatar(participant.userId) : null}
            </div>
            <div className="details">
              <h5>{title(participant)}</h5>
              <p>{description(participant)}</p>
            </div>
            <IconChevronRightGray />
          </div>
        ))}
      </AriaButton>
    </ProviderButtonWrap>
  );
};

const PickWrap = styled.div``;

const PickDate = styled.div``;

const PickProvider = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConfirmSlot = styled.div`
  --pad: calc(4rem + var(--ion-safe-area-bottom, 0px));
  min-height: calc(60vh - var(--pad));
  min-height: calc(70dvh - var(--pad));
  display: flex;
  flex-direction: column;
  button {
    margin-top: auto;
  }
`;

const SlotScheduleInfo = styled.div`
  margin: 1rem 0 1.5rem;
`;

const SlotDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0 1.5rem;
`;

const ConfirmInfoRow = styled.div`
  display: flex;
  gap: 0.6rem;
  align-items: baseline;
  font-weight: 500;
  letter-spacing: -0.32px;

  svg {
    display: block;
    transform: translateY(0.25rem);
  }

  .content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }
`;

const ConfirmSlotContent = styled.span`
  max-height: calc(60vh- 7rem);
  max-height: calc(70dvh - 7rem);
  overflow-y: auto;
`;

const Line = styled.div`
  padding-right: 0.5rem;
  &.strike {
    text-decoration: line-through;
    color: var(--Greys-Gray, #aaa7a0);
  }
`;

const PickSlot: FC = () => {
  const [{ selectedDate }, { getSlotsForSelectedDate }] =
    useBloc(SchedulerBloc);

  const slotsForDay = useMemo<AppointmentSlotGroup[]>(getSlotsForSelectedDate, [
    selectedDate
  ]);

  return (
    <PickSlotGrid>
      {slotsForDay.map((s) => (
        <SlotPreview key={`${s.from.toString()}-${s.to.toString()}`} slot={s} />
      ))}
    </PickSlotGrid>
  );
};

export const AppointmentSlotDetails: FC<{
  slot: AppointmentSlot;
  reschedule?: AppointmentResponse;
}> = ({ slot, reschedule }) => {
  const dateFormatTimeSimple: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric"
  };
  const dateFormatDate: Intl.DateTimeFormatOptions = {
    month: "short",
    weekday: "short",
    day: "numeric",
    year: "numeric"
  };

  const rescheduleFrom = reschedule?.start
    ? fromDate(new Date(reschedule.start), getLocalTimeZone())
    : undefined;
  const rescheduleTo = reschedule?.end
    ? fromDate(new Date(reschedule.end), getLocalTimeZone())
    : undefined;
  const differntDay = rescheduleFrom
    ? toCalendarDate(slot.from).compare(rescheduleFrom) !== 0
    : false;
  const differntTimeFrom = rescheduleFrom
    ? compareTime(slot.from, rescheduleFrom) !== 0
    : false;
  const differntTimeTo = rescheduleTo
    ? compareTime(slot.to, rescheduleTo) !== 0
    : false;

  const differntTime = differntTimeFrom || differntTimeTo;

  return (
    <SlotDetails className="slot-details">
      <ConfirmInfoRow>
        <div aria-label="Date" className="icon">
          <CalendarIconSmall />
        </div>
        <div className="content">
          {reschedule && differntDay && (
            <Line className={clsx({ strike: Boolean(reschedule) })}>
              <span>
                {new Date(reschedule.start).toLocaleDateString(
                  "en-US",
                  dateFormatDate
                )}
              </span>
            </Line>
          )}
          <Line>
            <span>
              {slot.from.toDate().toLocaleDateString("en-US", dateFormatDate)}
            </span>
          </Line>
        </div>
      </ConfirmInfoRow>
      <ConfirmInfoRow>
        <div aria-label="Time" className="icon">
          <TimeIconSmall />
        </div>
        <div className="content">
          {reschedule && differntTime && (
            <Line className={clsx({ strike: Boolean(reschedule) })}>
              <span>
                {new Date(reschedule.start).toLocaleTimeString(
                  "en-US",
                  dateFormatTimeSimple
                )}
                {" – "}
                {new Date(reschedule.end).toLocaleTimeString(
                  "en-US",
                  dateFormatTimeSimple
                )}
              </span>
            </Line>
          )}
          <Line>
            <span>
              {slot.from
                .toDate()
                .toLocaleTimeString("en-US", dateFormatTimeSimple)}
              {" – "}
              {slot.to
                .toDate()
                .toLocaleTimeString("en-US", dateFormatTimeSimple)}
            </span>
          </Line>
        </div>
      </ConfirmInfoRow>
    </SlotDetails>
  );
};

type SchedulerPropsBase = {
  view: "pick-date" | "pick-slot" | "confirm-slot" | "pick-provider";
  avatar?: (id: string) => ReactElement;
  handleBookAppointment?: () => void;
  scheduleInfo?: ReactNode;
  bookAppointmentText?: ReactNode;
  type?: ScheduleAppointmentTypes;
  rescheduleAppointment?: AppointmentResponse;
};

type SchedulerPropsPickDate = SchedulerPropsBase & {
  view: "pick-date";
  type: ScheduleAppointmentTypes;
};

type SchedulerPropsPickSlot = SchedulerPropsBase & {
  view: "pick-slot";
};

type SchedulerPropsPickProvider = SchedulerPropsBase & {
  view: "pick-provider";
  avatar: (id: string) => ReactElement;
};

type SchedulerPropsConfirmSlot = SchedulerPropsBase & {
  view: "confirm-slot";
  avatar: (id: string) => ReactElement;
  handleBookAppointment: () => void;
  scheduleInfo: ReactNode;
  bookAppointmentText: ReactNode;
};

const Scheduler: FC<
  | SchedulerPropsPickDate
  | SchedulerPropsPickSlot
  | SchedulerPropsConfirmSlot
  | SchedulerPropsPickProvider
> = ({
  view,
  avatar,
  handleBookAppointment,
  scheduleInfo,
  bookAppointmentText,
  type,
  rescheduleAppointment
}) => {
  const [
    { selectedDate, availableSlotGroups, selectedSlot, selectedSlotGroup },
    {
      setFocusedDate,
      initDatePickerView,
      handleDateFocusChanged,
      appointmentType
    }
  ] = useBloc(SchedulerBloc);

  useEffect(() => {
    if (view !== "pick-date") return;
    void initDatePickerView({
      type,
      rescheduleAppointment
    }).catch((e: unknown) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
  }, [type, view]);

  const slotsSectionRef = useRef<HTMLDivElement>();

  const handleDateChanged = (d: DateValue) => {
    setFocusedDate(d);
    setTimeout(() => {
      slotsSectionRef.current?.scrollIntoView({
        behavior: "smooth"
      });
    }, 60);
  };

  const state = useDatePickerState({});
  const elRef = React.useRef(null);

  const { calendarProps } = useDatePicker({}, state, elRef);

  const availableDays: Set<string> = new Set();

  for (const s of availableSlotGroups) {
    availableDays.add(dateToString(s.from));
  }

  return (
    <>
      {view === "pick-date" && (
        <>
          <PickWrap
            className={clsx({
              dateIsSelected: Boolean(selectedDate)
            })}
          >
            <PickDate>
              <Calendar
                {...calendarProps}
                availableDays={availableDays}
                onChange={handleDateChanged}
                autoFocus={false}
                value={selectedDate}
                onFocusChange={handleDateFocusChanged}
              />
            </PickDate>
          </PickWrap>
        </>
      )}
      {view === "pick-slot" && selectedDate && <PickSlot />}
      {view === "pick-provider" && selectedSlotGroup && (
        <>
          <PickProvider>
            {selectedSlotGroup.slots.map((s) =>
              s.participants.map((p) => (
                <ProviderPreview
                  key={`${p.userId}-${s.from.toString()}`}
                  slot={s}
                  avatar={avatar}
                />
              ))
            )}
          </PickProvider>
        </>
      )}
      {view === "confirm-slot" && selectedSlot && (
        <>
          <ConfirmSlot className="confirm-slot">
            <ConfirmSlotContent>
              {selectedSlot.participants.map(() => (
                <ProviderPreview
                  slot={selectedSlot}
                  avatar={avatar}
                  key={selectedSlot.participants[0].userId}
                  appointmentType={appointmentType}
                  disabled
                />
              ))}
              <AppointmentSlotDetails
                slot={selectedSlot}
                reschedule={rescheduleAppointment}
              />
              {scheduleInfo && (
                <SlotScheduleInfo>{scheduleInfo}</SlotScheduleInfo>
              )}
            </ConfirmSlotContent>
            <Button onPress={handleBookAppointment} fullWidth>
              {bookAppointmentText}
            </Button>
          </ConfirmSlot>
        </>
      )}
    </>
  );
};

export default Scheduler;
